import React from 'react'
import './tags.styles.scss'
import { ReactComponent as AltTag } from "../../../images/altTag.svg"
import bio from "../../../images/bio.png"
import gluten from "../../../images/gluten-free.png"
import lactose from "../../../images/lactose-free.png"
import coop from "../../../images/LogoCoop-8.png"
import salt from "../../../images/no-salt.png"
import sugar from "../../../images/no-sugar.png"
import lieviti from "../../../images/Senza Lieviti .png"
import vegan from "../../../images/vegan.png"
import { history } from '../../../App'

const Tag = ({ item }) => {

    const goToDetails = (tag) => {
        history.push({
            pathname: `/search`,
            state: {
                tag: tag,
            }
        })
    }

    const renderTag = () => {
        switch (item.id) {
            case "SENZA_LATTOSIO":
                return <img src={lactose} />
            case "BIO":
                return <img src={bio} />
                
            case "SENZA_LIEVITI":
                return <img src={lieviti} />
                
            case "SENZA_SALE":
                return <img src={salt} />
                
            case "SENZA_ZUCCHERO":
                return <img src={sugar} />
                
            case "VEGANO":
                return <img src={vegan} />
                
            case "PRODOTTI_MARCHIO_COOP":
                return <img src={coop} />
                
            case "SENZA_GLUTINE":
                return <img src={gluten} />
                
            default:
                return <AltTag />
                
        }
    }

    return (
        <div className='tags' onClick={() => goToDetails(item.id)}>
            <div className='tags-image'>
                {/* {renderTag()} */}
                <img src={item?.imageUrl} alt={item?.description} />
            </div>
            <div className='tags-text'>
                {item?.description}
            </div>
        </div>
    )
}

export default Tag